






































































import * as membershipService from "@/api/membershipService";
import { updateUser } from "@/api/userService";
import { QuittingReason } from "@/interfaces/User";
import { useNotifier } from "@/providers/notifier";
import { userModule } from "@/store/modules/user";
import { defineComponent, ref, inject } from "@vue/composition-api";

export default defineComponent({
  name: "CancelMembershipStepFour",
  setup() {
    const nextStep = inject("nextStep") as () => void;
    const previousStep = inject("previousStap") as () => void;
    const setCancelMembershipDialogVisible = inject("setCancelMembershipDialogVisible") as (val: boolean) => void;
    const getMembership = inject("getMembership") as () => Promise<void>;

    const notify = useNotifier();
    const loading = ref(false);
    const quittingReason = ref("" as QuittingReason);
    const dropdownOptions = [
      { text: "Vi skal ta pause fra undervisningen", value: "pause" },
      { text: "Vi har nådd målet vi satte oss", value: "reachedGoal" },
      { text: "Vi får hjelp av noen andre", value: "helpFromOthers" },
      { text: "Vi er ikke fornøyd med tjenesten", value: "notHappyWithTheService" },
      { text: "Personlig lærer var ikke en god match", value: "PLWasBadMatch" },
      { text: "Annet", value: "other" },
    ];
    const quittingDescription = ref("");

    async function cancelMembership() {
      loading.value = true;
      const uid = userModule.state.userId;

      try {
        await Promise.all([
          membershipService.cancelMembership(userModule.state.userId),
          updateUser({
            quittingReason: quittingReason.value,
            quittingDescription: quittingDescription.value,
            uid,
          }),
        ]);
        if (getMembership) await getMembership();
        nextStep();
      }
      catch (e) {
        // TODO: Implement global error handler (APP-2703).
        // this.$handleError(e);
        notify({
          title: "Feil",
          message: e.response.data,
          type: "error",
        });
      }
      loading.value = false;
    }

    return {
      cancelMembership,
      dropdownOptions,
      loading,
      nextStep,
      previousStep,
      quittingDescription,
      quittingReason,
      setCancelMembershipDialogVisible,
    };
  },
});



























































import { computed, defineComponent, inject, PropType } from "@vue/composition-api";
import Membership from "@/interfaces/Membership";
import useAsyncData from "@/hooks/useAsyncData";
import { getClosedProjects } from "@/api/projectService";
import { userModule } from "@/store/modules/user";
import ProfilePhoto from "@/components/profile/ProfilePhoto.vue";

export default defineComponent({
  components: { ProfilePhoto },
  name: "CancelMembershipStepThree",
  props: {
    membership: {
      type: Object as PropType<Membership>,
      required: true,
    },
  },
  setup() {
    const nextStep = inject("nextStep") as () => void;
    const setCancelMembershipDialogVisible = inject(
      "setCancelMembershipDialogVisible"
    ) as (val: boolean) => void;

    function continueMembership() {
      setCancelMembershipDialogVisible(false);
    }

    const sellerProfile = computed(() => {
      if (projects.value?.length) return projects.value[0].seller?.profile;
      return null;
    });

    const { isLoading, data: projects } = useAsyncData(
      () => getClosedProjects("customer", userModule.state.userId),
    );

    const sellerName = computed(() => {
      return sellerProfile.value ? sellerProfile.value.firstName : "den personlige læreren deres";
    });

    return {
      isLoading,
      nextStep,
      continueMembership,
      projects,
      sellerName,
      sellerProfile,
    };
  },
});

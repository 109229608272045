


































import { computed, defineComponent, PropType } from "@vue/composition-api";
import Membership from "@/interfaces/Membership";
import Moment from "@/services/moment";

export default defineComponent({
  name: "MembershipInfoCustomer",
  props: {
    membership: {
      type: Object as PropType<Membership>,
      required: true,
    },
  },
  setup(props) {
    const periodStart = props.membership.subscription?.current_period_start
      ? Moment.unix(props.membership.subscription?.current_period_start).format("Do MMM")
      : "";

    const periodEnd = props.membership.periodEnd
      ? Moment.unix(props.membership.periodEnd).format("Do MMM")
      : "";

    const endPrice = computed(() => {
      return (props.membership.subscription?.plan.amount || 0) / 100;
    });

    const used = props.membership.hours && (props.membership.hours * 60 > props.membership.balance)
      ? Math.round((props.membership.hours * 60 - props.membership.balance) / 60 * 10) / 10
      : 0;

    const usedPercent = props.membership.hours
      ? ((props.membership.hours - used) / props.membership.hours) * 100
      : 0;

    const usedReadable = String(used).replace(".", ",");

    const productName = props.membership.product?.name;

    return {
      endPrice,
      periodEnd,
      periodStart,
      productName,
      used,
      usedPercent,
      usedReadable
    };
  },
});

<template>
  <v-container>
    <ul class="py-6 px-10">
      <li>
        <strong>Om medlemskap</strong><br><br>
        Medlemskapet er et månedsabonnement som begynner å løpe én uke etter den første bli kjent-timen, og varer til
        samme dato i neste måned.<br><br>Betaling går deretter på samme dato hver måned. Har man for eksempel
        bli kjent-timen 10. januar, blir første betaling 17. januar og neste betaling 17.februar.<br><br>Medlemskapet
        løper til det blir sagt opp (akkurat som Netflix og Spotify!). Det er ingen bindingstid (minimum er én måned).<br><br>
      </li>
      <li>
        <strong>Hva er inkludert i medlemskapet?</strong><br><br>
        Inkludert i ditt medlemskap er et fast antall timer med et Learnlink-forbilde som skreddersyr en utviklingsplan
        tilpasset deres individuelle behov. Ubrukte undervisningstimer doneres til Learnlink.no sin støtteordning for
        elever som trenger det.<br><br>Inkludert er også fremgangsrapporter på e-post til foreldre etter hver time og
        perioderapporter minst hver annen måned. Dere vil også få tilgang til læringsinnhold, oppgaver og aktiviteter
        som skal til for å nå målene deres.<br><br>
      </li>
      <li>
        <strong>Endringer i medlemskapet</strong><br><br>
        Endringer på tidspunkt for timer eller endringer i medlemskapet avtales direkte med Learnlink-forbildet deres.<br><br>
      </li>
      <li>
        <strong>Ferier</strong><br><br>
        Learnlink-forbildene er ettertraktede, så om man ikke ønsker undervisning i en periode frigjøres læreren for å
        hjelpe andre. Om dere har redusert behov, anbefaler vi å justere medlemskapet.<br><br>Vi anbefaler kontinuerlig
        læring gjennom hele året! Ved ferier kan man for eksempel få oppgaver og fokusområder man kan jobbe med til
        neste undervisningstime.<br><br>
      </li>
    </ul>
  </v-container>
</template>

<script>
export default {
  name: "GeneralMembershipInfo",
};
</script>

































import { defineComponent, inject, PropType } from "@vue/composition-api";
import Membership from "@/interfaces/Membership";

export default defineComponent({
  name: "CancelMembershipStepFive",
  props: {
    membership: {
      type: Object as PropType<Membership>,
      required: true,
    },
  },
  setup() {
    const setCancelMembershipDialogVisible = inject("setCancelMembershipDialogVisible") as (val: boolean) => void;

    function close() {
      setCancelMembershipDialogVisible(false);
    }

    return {
      close,
    };
  },
});




































import {
  defineComponent,
  PropType,
  reactive,
} from "@vue/composition-api";
import Membership from "@/interfaces/Membership";
import CancelMembershipDialog from "./CancelMembershipDialog.vue";

export default defineComponent({
  name: "ChangeMembershipButtons",
  components: { CancelMembershipDialog },
  props: {
    membership: {
      type: Object as PropType<Membership>,
      required: true,
    },
  },
  setup() {
    const vm = reactive({
      cancelMembershipDialogVisible: false,
      pauseMembershipDialogVisible: false,
    });

    return {
      vm
    };
  },
});

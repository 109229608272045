





















import { listReceipts } from "@/api/membershipService";
import ReceiptCard from "@/components/membership/ReceiptCard.vue";
import useAsyncData from "@/hooks/useAsyncData";
import { userModule } from "@/store/modules/user";
import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "PaymentHistory",
  components: { ReceiptCard },
  setup() {
    const {
      data: receipts,
      isLoading,
    } = useAsyncData(() => listReceipts(userModule.state.userId));

    return {
      receipts,
      loading: isLoading,
    };
  },
});





















import CancelMembershipStepOne from "./CancelMembershipStepOne.vue";
import CancelMembershipStepTwo from "./CancelMembershipStepTwo.vue";
import CancelMembershipStepThree from "./CancelMembershipStepThree.vue";
import CancelMembershipStepFour from "./CancelMembershipStepFour.vue";
import CancelMembershipStepFive from "./CancelMembershipStepFive.vue";
import { computed, defineComponent, PropType, provide, ref, watchEffect } from "@vue/composition-api";
import Membership from "@/interfaces/Membership";

const steps = [CancelMembershipStepOne, CancelMembershipStepTwo, CancelMembershipStepThree, CancelMembershipStepFour, CancelMembershipStepFive];

export default defineComponent({
  name: "CancelMembershipDialog",
  props: {
    membership: {
      type: Object as PropType<Membership>,
      required: true,
    },
    value: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const _value = ref(props.value);
    watchEffect(() => {
      emit("input", _value);
    });

    const step = ref(0);

    const currentStep = computed(() => steps[step.value]);

    provide("nextStep", function() {
      step.value++;
    });

    provide("previousStap", function() {
      step.value--;
    });

    provide("setCancelMembershipDialogVisible", (val: boolean) => {
      _value.value = val;
      if (!val) step.value = 0;
    });

    return {
      currentStep,
      step,
      _value
    };
  },
});











































































import { defineComponent, PropType, inject } from "@vue/composition-api";
import Membership from "@/interfaces/Membership";

export default defineComponent({
  name: "CancelMembershipStepTwo",
  props: {
    membership: {
      type: Object as PropType<Membership>,
      required: true,
    },
  },
  setup() {
    const nextStep = inject("nextStep") as () => void;
    const setCancelMembershipDialogVisible = inject(
      "setCancelMembershipDialogVisible"
    ) as (val: boolean) => void;

    function continueMembership() {
      setCancelMembershipDialogVisible(false);
    }

    function cancelMembership() {
      nextStep();
    }

    function openIntercom() {
      // TODO: Add Intercom.
      // window.Intercom("showNewMessage", "Hei! Jeg er ikke helt fornøyd med: ");
    }

    return {
      cancelMembership,
      continueMembership,
      openIntercom,
    };
  },
});








































































import GeneralMembershipInfo from "@/components/membership/GeneralMembershipInfo.vue";
import { defineComponent, provide } from "@vue/composition-api";
import { getMembership } from "@/api/membershipService";
import { userModule } from "@/store/modules/user";
import ChangeMembershipButtons from "@/components/membership/CancelMembershipDialog/ChangeMembershipButtons.vue";
import MembershipInfoCustomer from "@/components/membership/MembershipInfoCustomer.vue";
import PaymentHistory from "@/components/membership/PaymentHistory.vue";
import useAsyncData from "@/hooks/useAsyncData";

export default defineComponent({
  name: "Membership",
  components: {
    GeneralMembershipInfo,
    ChangeMembershipButtons,
    MembershipInfoCustomer,
    PaymentHistory,
  },
  setup() {
    const { isLoading, data: membership, refresh } = useAsyncData(() =>
      getMembership(userModule.state.userId),
    );

    provide("getMembership", refresh);

    return {
      isLoading,
      membership,
    };
  },
});

import { Weekday } from "@/helpers/availabilityUtils";
import Project, { ActiveProject, ClosedProject } from "@/interfaces/Project";
import { UserType } from "@/interfaces/UserType";
import learnlinkAPI from "./learnlinkAPI";

const ENDPOINT = "/app/projects";

export const getProject = async(projectID: string): Promise<Project> => {
  const projectRes = await learnlinkAPI.axios.get<Project>(
    `${ENDPOINT}/${projectID}`
  );
  return projectRes.data;
};

export const getActiveProjects = async(userType: UserType, uid: string): Promise<ActiveProject[]> => {
  const projectsRes = await learnlinkAPI.axios.get<ActiveProject[]>(`${ENDPOINT}/active/${userType}/${uid}`);
  return projectsRes.data;
};

export const getClosedProjects = async(userType: UserType, uid: string): Promise<ClosedProject[]> => {
  const projectsRes = await learnlinkAPI.axios.get<ClosedProject[]>(`${ENDPOINT}/closed/${userType}/${uid}`);
  return projectsRes.data;
};

export const completeProjectBySeller = async(
  projectID: string,
  accomplishments: string,
  improvementAreas: string,
  studentContinuing: boolean,
  terminationReason: string,
  weekdays: Weekday[],
): Promise<ActiveProject> => {
  const projectRes = await learnlinkAPI.axios.put(
      `${ENDPOINT}/completedBySeller/${projectID}`, {
        projectID,
        accomplishments,
        improvementAreas,
        studentContinuing,
        terminationReason,
        weekdays,
      }
  );
  return projectRes.data;
};

export const saveStudentName = async(projectID: string, studentName: string): Promise<ActiveProject> => {
  const projectRes = await learnlinkAPI.axios.put<ActiveProject>(
    `${ENDPOINT}/${projectID}/studentName`, { studentName }
  );
  return projectRes.data;
};
